import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return ({
    itemContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 2rem',
      textAlign: 'center',
    },
    content:{
        lineHeight:'1.5rem'
    }
  })
})

export default useStyles

