import React from "react"
import { useQueryParam, StringParam } from "use-query-params"
import { useStaticQuery, Link, graphql } from "gatsby"
import { Grid, IconButton } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Layout from "../components/Layout"
import useStyles from '../styles/challengeItemPageStyles'
import SEO from '../components/seo'


const ReactMarkdown = require('react-markdown')

const ChallengeItemPage = () => {
  const classes = useStyles()
  const query = useStaticQuery(graphql`
  {
    allFile(filter: {childMarkdownRemark: {frontmatter: {id: {eq: "digitalchallenges"}}}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            title
            seoDescription
            seoTitle
            seoKeywords
            challenges {
              img {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }, 
              detailImg {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }, 
              id
              subtitle
              title
              cols
              seoDescription
              seoTitle
              seoKeywords
            }
          }
        }
      }
    }
  }
  `)
  const [id] = useQueryParam("id", StringParam);
  const challengeItems = query.allFile.nodes[0].childMarkdownRemark.frontmatter.challenges
  const item = challengeItems.find((item) => item.id === id)
  const image = item?.detailImg.childImageSharp.fluid.originalImg
  const seoTitle = item?.seoTitle
  const seoDescription = item?.seoDescription
  const seoKeywords = item?.seoKeywords
  const title = item?.title
  const subtitle = item?.subtitle

  return (
    <Layout>
      <SEO 
        title={seoTitle !== undefined ? seoTitle : title} 
        description ={seoDescription !== undefined ? seoDescription : subtitle} 
        keywords ={seoKeywords !== undefined ? seoKeywords : title}
      />
      <Link to='/challengecards'>
        <IconButton color="primary" aria-label="upload picture" component="span">
          <ArrowBackIosIcon />
        </IconButton>
      </Link>
      <Grid container justify="center" alignItems='center'>
        <Grid item xs={12} sm={6}>
          <div className={classes.itemContent}>
            <div>
              <img src={image} alt={item?.title} title={item?.subtitle} className={classes.image} />
            </div>
          </div>
        </Grid>       
      </Grid>
    </Layout>
  )
}

export default ChallengeItemPage
